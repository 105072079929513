
export default {
  email: 'Correo electrónico',
  password: 'Contraseña',
  password_confirm: 'Confirmar contraseña',
  new_password: 'Nueva contraseña',
  new_password_confirm: 'Confirmar nueva contraseña',
  logout: 'Cerrar sesión',
  first_name: 'Nombre',
  last_name: 'Apellido',
  identify_document: 'Documento de identidad',
  gender: 'Género',
  employment: 'Cargo',
  department: 'Departamento/Área',
  academic_degree: 'Nivel académico',
  admission: 'Fecha de ingreso',
  age_range: 'Rango de edad',
  antiquity_range: 'Rango de Antigüedad',
  job_type: 'Tipo de contratación',
  jobType: 'Tipo de contratación',
  charge: 'Cargo',
  country: 'País',
  headquarter: 'Sede',
  optionalDemo1: 'Demográfico adicional 1',
  optionalDemo2: 'Demográfico adicional 2',
  phone_number: 'Número de teléfono',
  accept: 'Aceptar',
  cancel: 'Cancelar',
  save: 'Guardar',
  next: 'Siguiente',
  back: 'Regresar',
  enable: 'Habilitar',
  disable: 'Inhabilitar',
  add: 'Agregar',
  trash: 'Borrar',
  import: 'Importar',
  file: 'Archivo',
  save_changes: 'Guardar cambios',
  error_fix: 'Corregir errores',
  confirm_changes: 'Confirmar cambios',
  back_home: 'Volver al inicio',
  back_list: 'Volver al listado',
  name: 'Nombre',
  sector: 'Sector',
  address: 'Dirección',
  enterprise: 'Empresa',
  enterprise_size: 'Tamaño de la empresa',
  language: 'Idioma',
  employees_number: 'Número de empleados',
  demographic_cuts: 'Cortes demográficos',
  duration: 'Duración',
  start_date: 'Fecha de inicio',
  edit: 'Editar',
  edit_questions: 'Editar cuestionario',
  view_details: 'Ver detalles',
  birthdate: 'Fecha de nacimiento',
  confirm_save: 'Confirmar y crear',
  confirm_generate: 'Confirmar y generar',
  continue: 'Continuar',
  start_poll: 'Comenzar encuesta',
  answer_poll: 'Responder encuesta',
  finish: 'Finalizar',
  back_to_dashboard: 'Volver al tablero',
  select_image: 'Seleccionar logo de la compañía',
  continue_filling: 'Continuar llenando',
  languages: 'Idiomas',
  begin: 'Comenzar',
  answer: 'Respuesta',
  resend: 'Reenviar',
  enterprise_name: 'Nombre de la empresa',
  pulses_qty_add: 'Cantidad de Pulse a agregar:',
  engagements_qty_add: 'Cantidad de Engagement a agregar:',
  admin_extension: 'Administrar solicitudes de extensión',
  reject: 'Rechazar',
  see_requests: 'Ver solicitudes enviadas',
  identify_type: 'Tipo de documento de identidad',
  identify_type_s: 'DI',
  exist_identification: 'El número de identificación ingresado ya existe.',
  no_permissions: 'No posee permisos para realizar esta acción',
  engagementsQty: 'Cantidad de Engagement',
  pulsesQty: 'Cantidad de Pulse',
  order: 'Orden',
  required_field: 'Campo obligatorio',
  valid_email: 'Ingrese un correo electrónico válido.',
  close: 'Cerrar',
  invitation_url: 'Enlace de invitación',
  url_copied: 'Enlace copiado',
  copy_invitation_url_title: 'Enlace para colaboradores sin correo',
  copy_invitation_url: 'Este enlace lo puedes copiar y enviar a los colaboradores que estén participando en la medición y no tengan correo electrónico o no les haya llegado el correo de invitación. Para poder responder la encuesta, los colaboradores deberán ingresar su documento de identidad con el cual se han cargado en la plataforma.',
  send_invitation_url: 'Enviar enlace de invitacion',
  modal_title: 'Editar datos de empleado',
  preview: 'Previsualización',
  close_poll: 'Cerrar medición',
  poll_delete_title: 'Cerrar medición',
  master_references_delete_poll: 'Al cerrar la medición ninguno de sus empleados podrá participar en la encuesta y esta acción no podrá ser cambiada de ninguna manera. Toda la información para reportes se guardará hasta este punto.',
  select_video: 'Seleccionar video para el correo',
  go_suite: 'Volver a Suite',
  send_message: 'Enviar mensaje',
  refresh: 'Refrescar',
  generic_link_btn: 'Enlace para Colaboradores sin Correo',
  the_reminders: 'Los recordatorios',
  may_take_while: 'Esta acción puede tardar unos minutos',
  export: 'Exportar',
  please_wait: 'Por favor espere'
};
