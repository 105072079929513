
<template>
  <v-stepper-content :step="step">
    <x-technical-requirements></x-technical-requirements>
    <v-row row wrap>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-row row wrap>
              <v-col cols="12" md="6">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-card-account-details</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('engagement.poll_name') }}</h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading">
                      {{ engagement.name }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="6">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-card-account-details-outline</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('engagement.external_name') }}</h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading">
                      {{ engagement.displayName || engagement.name }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>

            <v-divider></v-divider>
            <v-row row wrap>
              <v-col cols="12" md="6">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-calendar-star</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('engagement.date_delivery') }}</h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading">
                      {{ formatDate(engagement.deliveredAt) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="6">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-calendar-minus</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('engagement.poll_valid_until') }}</h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading">
                      {{ formatDate(engagement.validUntil) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="6">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-camera-timer</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('engagement.time_zone') }}</h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading">
                      {{ engagement.timeZone }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="6">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-calendar-clock</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('engagement.send_reminders') }}</h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading">
                      {{ engagement.switchDate ? 'Si' : 'No' }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>

            <v-divider></v-divider>
            <v-row row wrap>
              <v-col cols="12" md="6">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-file-question</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('engagement.questionnaire') }}</h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading">
                      {{ (engagement.questionnaires.find(e => e.value === engagement.radios) || {}).text }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="6">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>fa-coins</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">
                        {{ `${ !engagement.newReceivers || engagement.newReceivers <= 0 ? 0 : engagement.totalPrice} ${$t('engagement.token_unit')}` }}
                      </h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading">
                      {{ `${ !engagement.newReceivers || engagement.newReceivers <= 0 ? $t('engagement.poll_paid') : $t('engagement.workshop_cost') + ' ' + (engagement.newReceivers <= 0 ? engagement.totalReceivers : engagement.newReceivers ) +' '+ $t('engagement.participants') }`}}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
            <v-row row wrap>
              <v-col cols="12" md="8">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-comment-question</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('engagement.want_open_question') }}</h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading">
                      {{
                        engagement.switchQuestion
                          ? $t('dashboard.poll_completed')
                          : $t('dashboard.poll_incompleted')
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" v-if="engagement.switchQuestion">
                <v-list>
                  <v-list-group
                    v-for="(item, $idx) in engagement.opens"
                    :key="$idx"
                    v-model="item.active"
                    prepend-icon="mdi-comment-question"
                    no-action
                  >
                    <template v-slot:activator>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>{{ item.question }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>

                    <v-list-item
                      v-for="(subItem, $i) in item.options"
                      :key="$i"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{ subItem }}</v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-icon>mdi-radiobox-blank</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list-group>
                </v-list>
              </v-col>
            </v-row>

            <v-divider></v-divider>
            <v-row row wrap>
              <v-col cols="12">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-email-open-outline</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('engagement.personalization') }}</h6>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12">
                <v-row align="center" justify="center">
                  <v-col lg="10" sm="9" class="d-none-xs-only">
                    <v-tabs v-model="engagement.active" class="fitTabs" show-arrows fixed-tabs>
                      <v-tab v-for="(it, $index) in tabItems" :key="$index" :href="`#`+it.value" @click="changeTab(it.value)">
                        {{ it.text }}
                      </v-tab>
                    </v-tabs>
                  </v-col>
                  <v-col cols="12" class="d-none-sm-and-up">
                    <v-select
                      v-model="engagement.active"
                      :items="tabItems"
                      @change="changeTab"
                    ></v-select>
                  </v-col>
                  <v-col cols="4" v-if="tabSelected != 3" class="d-flex justify-center">
                    <v-btn color="primary" @click="displayPreview">{{ $t('input.preview') }}</v-btn>
                  </v-col>
                </v-row>

                <v-container grid-list-md text-xs-center>
                  <v-tabs-items v-model="engagement.active">

                    <v-tab-item :value="`tab-1`">
                      <v-container grid-list-md text-xs-center>
                        <v-col cols="12">
                          <v-text-field
                            v-model="engagement.pollInvitation.subject"
                            :disabled="engagement.status != 'pending'"
                            :filled="engagement.status != 'pending'"
                            v-bind:style="engagement.status != 'pending' ? 'cursor: not-allowed !important;' : ''"
                            light
                            :label="$t('engagement.message_subject')"
                            :name="$t('engagement.message_subject') | lowerCase"
                            :append-outer-icon="$t('help.icon')"
                            @click:append-outer="$store.dispatch('help/display', $t('help.engagement.create.subject'))"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12">
                           <quill-editor ref="pollInvitationBody"
                            v-model="engagement.pollInvitation.body"
                            :options="editorOption"
                            :disabled="engagement.status != 'pending'"
                            :filled="engagement.status != 'pending'"
                            v-bind:style="engagement.status != 'pending' ? 'cursor: not-allowed !important;' : ''">
                          </quill-editor>
                          <!--
                          <v-text-field
                            :label="$t('input.select_video')"
                            @click="pickFile"
                            v-model="pollInvitationVideo.name"
                            prepend-icon="attach_file"
                            :color="videoColor"
                            :append-outer-icon="$t('help.icon')"
                            @click:append-outer="$store.dispatch('help/display', $t('help.enterprise.create.logo'))"
                          ></v-text-field>
                          <input
                            type="file"
                            style="display: none"
                            ref="videoInput"
                            accept="video/*"
                            @change="onFilePicked"
                          >
                          -->
                        </v-col>
                      </v-container>
                    </v-tab-item>

                    <v-tab-item :value="`tab-2`">
                      <v-container grid-list-md text-xs-center>
                        <v-col cols="12">
                          <v-text-field
                            v-model="engagement.reminderMail.subject"
                            light
                            :label="$t('engagement.message_subject')"
                            :name="$t('engagement.message_subject') | lowerCase"
                            :append-outer-icon="$t('help.icon')"
                            @click:append-outer="$store.dispatch('help/display', $t('help.engagement.create.subject'))"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12">
                           <quill-editor ref="pollReminderBody"
                              v-model="engagement.reminderMail.body"
                              @updateInput="($event) => engagement.reminderMail.body = $event"
                              :options="editorOption">
                          </quill-editor>
                          <!--
                           <v-text-field
                            :label="$t('input.select_video')"
                            @click="pickFile2"
                            v-model="pollReminderVideo.name"
                            prepend-icon="attach_file"
                            :color="videoColor2"
                            :append-outer-icon="$t('help.icon')"
                            @click:append-outer="$store.dispatch('help/display', $t('help.enterprise.create.logo'))"
                          ></v-text-field>
                          <input
                            type="file"
                            style="display: none"
                            ref="videoInput2"
                            accept="video/*"
                            @change="onFilePicked2"
                          >
                          -->
                        </v-col>
                      </v-container>
                    </v-tab-item>
                    <!--
                    <v-tab-item :value="`tab-3`">
                      <v-container grid-list-md text-xs-center>
                        <v-col cols="12">
                          <v-textarea
                            v-model="engagement.thankMessage"
                            light
                            :label="$t('engagement.tk_message')"
                            :name="$t('engagement.tk_message') | lowerCase"
                           :append-icon="$t('help.icon')"
                            @click:append="$store.dispatch('help/display', $t('help.engagement.create.thankMessage'))"
                          ></v-textarea>
                        </v-col>

                      </v-container>
                    </v-tab-item>
                    -->
                  </v-tabs-items>
                </v-container>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- <v-dialog
        v-model="display"
        max-width="58.8rem"
      >
        <div>
          <div style="background: #f3f3f3; wi">
            <p style="padding-left: 1em; margin-bottom: 0"> {{$t('pulses.message_subject') }}: <strong> {{message.title}} </strong></p>
          </div>
          <div>
            <title>OCC Solutions - Pulse</title>
          </div>

          <div style="background: #f3f3f3;">
            <table width="100%">
              <tr>
                <td align="center" bgcolor="#ffffff" style="border-bottom: 3px solid #f3f3f3;">
                  <a href="#" target="_blank" style="color: #2E6B9C; font-weight: 900; text-decoration: none; line-height: 0.8;">
                    <h1 style="font-size: 3rem; margin: 0; padding: 30px 0;">OCC Solutions - Pulse</h1>
                  </a>
                </td>
              </tr>
              <tr style="font-family: Arial, sans-serif;">
                <td bgcolor="#FFF" style="padding: 40px 30px 40px 30px;">
                  <table border="0" cellpadding="0" cellspacing="0" width="100%">
                    <tr>
                      <td style="color: #333333; font-size: 16px; padding: 20px 0 30px 0;">
                        <div style="padding-left: 1em; margin-bottom: 0"  class="ql-editor" v-html="message.body">  </div>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td align="center" bgcolor="#FFF" style="padding: 40px 0;color: #2E6B9C; font-family: Arial, sans-serif; font-size: 12px; border-top: 3px solid #f3f3f3;">
                  <p>
                    {{$t('pulses.all_right')}} OCC Solutions - Pulse <br/>
                    info@occsolutions.org | +57 (1) 642 1300
                  </p>
                  <div> {{ $t('pulses.automatic_message') }} <a href="#"> OCC pulse </a></div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </v-dialog> -->
    </v-row>
    <v-container>
      <v-row row wrap>
        <v-col cols="12" sm="6">
          <v-btn
            block
            large
            @click="changeStep(+step - 1)"
          >{{ $t('input.back') }}</v-btn>
        </v-col>

        <v-col cols="12" sm="6">
          <x-async-btn large block
            class="white--text"
            :action="runValidate"
          >
            {{ edit ? $t('input.edit') : $t('input.confirm_save') }}
          </x-async-btn>
        </v-col>
      </v-row>
    </v-container>
    <x-poll-preview-email
      :show-preview="showModalPreview"
      :poll-name="engagement.displayName || engagement.name"
      :subject="message.title"
      :body="message.body"
      :attachments="message.attachments"
      @close="$event => showModalPreview = $event"
    ></x-poll-preview-email>
  </v-stepper-content>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  components: {
    quillEditor
  },
  props: {
    edit: Boolean,
    engagement: Object,
    step: String,
    nextAction: String,
    prevAction: String,
  },
  data() {
    return {
      tabSelected: 1,
      showModalPreview: false,
      message: {
        title: '',
        body: '',
        attachments: ''
      },
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['clean'],
            ['link'],
          ],
        },
        placeholder: ''
      },
      pollInvitationVideo: {
        src: '',
        name: '',
      },
      videoColor: 'primary',
      pollReminderVideo: {
        src: '',
        name: '',
      },
      videoColor2: 'primary'
    }
  },
  computed: {
    tabItems () {
      return [
        { text: this.$t('engagement.poll_invitation').toUpperCase(), value: 'tab-1' },
        { text: this.$t('engagement.reminder_mail').toUpperCase(), value: 'tab-2' }
      ]
    },
    pollReminderEditor() {
      return this.$refs.pollReminderBody.quill;
    },
    pollInvitationEditor() {
      return this.$refs.pollInvitationBody.quill;
    }
  },
  methods: {
    changeStep(step) {
      this.$emit('changeStep', step);
    },
    runValidate() {
      return new Promise((resolve) => {
        this.changeStep(+this.step + 1);
        resolve();
      });
    },
    formatDate (data) {
      const [year, month, day] = data.value.split('-')
      return `${day}/${month}/${year} a las ${data.hour}:00`
    },
    displayPreview() {
      if (this.tabSelected === 1) {
        this.message.title = this.engagement.pollInvitation.subject;
        this.message.body = this.engagement.pollInvitation.body;
        this.message.attachments = this.engagement.pollInvitation.file ? this.engagement.pollInvitation.file.name : ''
      } else {
        this.message.title = this.engagement.reminderMail.subject;
        this.message.body = this.engagement.reminderMail.body;
        this.message.attachments = this.engagement.reminderMail.file ? this.engagement.reminderMail.file.name : ''
      }
      this.showModalPreview = true;
    },
    pickFile() {
      this.$refs.videoInput.click();
    },
    onFilePicked($event) {
      const files = $event.target.files;

      if (files[0] !== undefined) {
        if (files[0].size > 50000000) {
          this.$store.dispatch('alert/error', this.$t('errors.video/size'));
          this.videoColor = 'error';
        } else {
          this.videoColor = 'primary';
          this.pollInvitationVideo.name = files[0].name;
          return this.pollInvitationVideo.name.lastIndexOf('.') > 0 ? this.readFile(files) : this.resetVideo();
        }
      } else this.resetVideo();
    },
    resetVideo() {
      this.pollInvitationVideo.src = '';
      this.pollInvitationVideo.name = '';
      this.engagement.pollInvitation.file = '';
    },
    readFile(files) {
      const fr = new FileReader();
      fr.readAsDataURL(files[0]);
      fr.addEventListener('load', () => {
        this.pollInvitationVideo.src = fr.result;
        this.engagement.pollInvitation.file = files[0];
      });
    },
    pickFile2() {
      this.$refs.videoInput2.click();
    },
    onFilePicked2($event) {
      const files = $event.target.files;

      if (files[0] !== undefined) {
        if (files[0].size > 50000000) {
          this.$store.dispatch('alert/error', this.$t('errors.video/size'));
          this.videoColor2 = 'error';
        } else {
          this.videoColor2 = 'primary';
          this.pollReminderVideo.name = files[0].name;
          return this.pollReminderVideo.name.lastIndexOf('.') > 0 ? this.readFile2(files) : this.resetVideo2();
        }
      } else this.resetVideo2();
    },
    resetVideo2() {
      this.pollReminderVideo.src = '';
      this.pollReminderVideo.name = '';
      this.engagement.reminderMail.file = '';
    },
    readFile2(files) {
      const fr = new FileReader();
      fr.readAsDataURL(files[0]);
      fr.addEventListener('load', () => {
        this.pollReminderVideo.src = fr.result;
        this.engagement.reminderMail.file = files[0];
      });
    },
    changeTab(val) {
      this.tabSelected = parseInt(val.substr(val.length - 1, 1));
    }
  },
  created() {
    this.editorOption.placeholder = this.$t('engagement.body_message');
  },
}
</script>

<style lang="css">
.ql-container {
  min-height: 170px !important;
}
@media only screen and (min-width: 710px) {
  .d-none-sm-and-up {
    display: none!important;
  }
}
@media only screen and (max-width: 709px) {
  .d-none-xs-only {
    display: none!important;
  }
}
</style>

<style>
  .fitTabs div.v-tabs__container {
    width: fit-content;
  }
</style>
