
export default {
  copyright: 'Copyright by OCC - All Rights Reserved',
  introduction: 'Introduction',
  model: 'The Model',
  results: 'Results',
  ranking: 'Ranking',
  open_questions: 'Open questions',
  commitment_belonging: 'Commitment+Belonging',
  index: 'Index',
  topic: 'Topic',
  page: 'Page',
  our_recommendations: 'Our recommendations',
  index_stack: [
    '1.    What is engagement and how is it measured?',
    '2.    How does our model works?',
    '3.    Methodology',
    '4.    Results',
    '4.1. Answers rate',
    '4.2. General score',
    '4.3. Results by dimensions',
    '4.4. Results by attributes',
    '5.    Dimensions ranking',
    '5.1. Dimensions ranking',
    '5.2. Attributes ranking',
    '5.3. Variables ranking',
    '6.    Dispersion',
    '6.1. Dispersion by dimensions',
    '6.2. Dispersion by attributes',
    '6.3. Dispersion by variables',
    '7.    Rates',
    '7.1. Rates by dimensions',
    '7.2. Rates by attributes',
    '7.2. Rates by variables',
    '8.    Open question clouds',
  ],
  index_stack_general: [
    '1.    What is engagement and how is it measured?',
    '2.    How does our model works?',
    '3.    Methodology',
    '4.    Results',
    '4.1. Results by dimensions',
    '4.2. Results by attributes',
    '5.    Dimensions ranking',
    '5.1. Dimensions ranking',
    '5.2. Attributes ranking',
    '5.3. Variables ranking',
    '6.    Dispersion',
    '6.1. Dispersion by dimensions',
    '6.2. Dispersion by attributes',
    '6.3. Dispersion by variables',
    '7.    Rates',
    '7.1. Rates by dimensions',
    '7.2. Rates by attributes',
    '7.3. Rates by variables',
    '8.    Open question clouds',
  ],
  recommendations_stack: [
    // tslint:disable-next-line:max-line-length
    'Make sure to give strategic importance to the Engagement, as a lever to achieve outstanding and sustainable results.',
    // tslint:disable-next-line:max-line-length
    'Remember that the Engagement and the Organizational Culture complement each other; managing both of them is vital in order to bring and hold the talent.',
    // tslint:disable-next-line:max-line-length
    'Empower each leader with the responsibility of managing the Engagement and culture. The human management areas facilitate and support.',
    // tslint:disable-next-line:max-line-length
    'Be inclusive and participative at the time of defining and closing gaps. Mobilize the collective inteligence generates a higher level of commitment.',
    // tslint:disable-next-line:max-line-length
    'It\'s vital for the process tracking in short cycles. Search for creatives ways of closing gaps and measure frequently. Our Pulse tool is designed for it.',
    // tslint:disable-next-line:max-line-length
    'Always report the results with opening and transparency and include theem in the leaders performance management process.',
    // tslint:disable-next-line:max-line-length
    'Finally, remember the importance of keeping focus. It is better to have less actions with high impact rather than having lots of actions with less positive effects.',
  ],
  what_is_engagement: 'What is engagement and why measure it?',
  // tslint:disable-next-line:max-line-length
  what_is_engagement_text: ['At OCC Solutions we understand Engagement as high level emotional commiment and belonging sense combined with enthusiasm and passion for the job and the organization, which manifests itself in a permanent desire of giving the best the whole time.'],
  what_is_engagement_positive: [
    'Do the best everyday and having passion for the job.',
    'Emotional commitment to the purpose, objectives and values of the Organization.',
    'High level of enthusiasm and dedication that a person feels towards their work.',
    'Confidence, integrity, bidirectional commitment and communication between the organization and people.',
    'Motivation to contribute to the success of the Organization with a greater sense of own well-being.',
  ],
  what_is_engagement_negative: [
    // tslint:disable-next-line:max-line-length
    'Happiness, like laughing with co-workers or enjoying a company party, says nothing about how involved or committed they are to the company or how hard they are working to achieve the Organization\'s purpose. It is a short-term measure that changes rapidly.',
    'Satisfaction of employees of their social and personal needs.',
    'A good benefits and compensation package.',
    // tslint:disable-next-line:max-line-length
    'The above only indicates how happy or content employees are, but does not address the level of motivation or emotional commitment. Satisfaction may be enough to retain employees, but it does not guarantee productivity.'
  ],
  // tslint:disable-next-line:max-line-length
  what_is_engagement_conclusion1: 'Employees who are highly engaged achieve better results, retain customers, rotate less frequently, and contribute to the alignment of the culture.',
  // tslint:disable-next-line:max-line-length
  what_is_engagement_conclusion2: 'Organizations with a higher level of Engagement attract and keep the best talent, better implement change processes and thus achieve outstanding and sustainable results.',
  what_is_engagement_quote: '“Organizations today are competing for talent and, therefore, the challenge of creating conditions that elevate people is an imperative, not just another fad.  Raising people\'s level of engagement and sense of belonging is a systemic effort, which includes having an inspiring higher purpose, a healthy and admirable culture, great leaders, a memorable employee experience, promoting learning and integral development, encouraging innovation and continuous change, increasing autonomy and operational excellence, to name a few.”',
  how_does_our_model_works: 'How does our model works?',
  // tslint:disable-next-line:max-line-length
  how_does_our_model_works_text: 'Our model is circular and is made up of six dimensions. Each dimension in turn has 3 attributes and each attribute has 3 questions called variables:',
  my_team: {
    title: 'My team',
    attributes: [
      'Team dynamics\n',
      'Communication and coordination\n',
      'Agility and decision making'
    ],
    desc: 'Hago parte de un equipo\ncomprometido con la Organización'
  },
  my_development_and_learning: {
    title: 'My development and learning',
    attributes: [
      'Opportunities of growth of my role\n',
      'Opportunities of growth in the organization\n',
      'Learning culture'
    ],
    desc: '\nMe veo creciendo en esta\nOrganización en el futuro'
  },
  opportunities_of_growth: 'Oportunidades de crecimiento en la Org.',
  positive_work_enviroment: {
    title: 'Positive work environment',
    attributes: [
      'Relationships qualitys\n',
      'People\'s treat\n',
      'Equity and transparency'
    ],
    desc: '\nLe recomendaría a amigo o familiar\nque trabaje en esta Organización'
  },
  the_leaders: {
    title: 'The leaders',
    attributes: [
      'My leader\n',
      'Support\n',
      'Our leaders'
    ],
    desc: 'Los líderes en esta\nOrganización me inspiran'
  },
  my_inspiration: {
    title: 'My inspiration',
    attributes: [
      'Sense of purpose\n',
      'Transcendental work\n',
      'They know me'
    ],
    desc: 'Si me ofrecieran un\ntrabajo similar en otra empresa, me\nquedaría donde estoy'
  },
  my_job: {
    title: 'My job',
    attributes: [
      'Reputation of the organization\n',
      'Reputation of my area\n',
      'Work environment'
    ],
    desc: 'Siento compromiso y\norgullo de trabajar en esta empresa'
  },
  indicators: 'Assessment Indicators',
  methodology: 'Methodology',
  // tslint:disable-next-line:max-line-length
  methodology_text: 'The survey contains 54 statements on a scale from 1 to 5 that presents the\nfollowing options:',
  methodology_list: [
    'I totally disagree',
    'I partially disagree',
    'Neither disagree nor agree',
    'I partially agree',
    'I totally agree',
  ],
  // tslint:disable-next-line:max-line-length
  enps_explanation1: 'The Employee Net Promoter Score is calculated based on the question, "I would recommend a friend or family member to work at this Organization."\nThis question is asked on a scale from 0 to 10.\nTo obtain the result, the grouping is subtracted of responses in 9 and 10 (promoters) minus the grouping of responses in 0 to 6 (detractors).',
  // tslint:disable-next-line:max-line-length
  enps_explanation2: '\nInterpretation: -100 to -10 Critical, -10 to 0 insufficient, 0 to +10 Acceptable, +10 to +30 Outstanding, and +30 Excellent.',
  // tslint:disable-next-line:max-line-length
  indicators_final_text: 'Finally, the survey has 3 open-ended questions that are displayed at the end of the report in a word cloud.',
  // tslint:disable-next-line:max-line-length
  methodology_conclusion: 'The scores of the different dimensions, variables and attributes are calculated by\nthe simple average of the responses.',
  results_text: 'In this section you will find the results of the survey.',
  total_obtained: 'Total obtained',
  total_sent: 'Total expected',
  answers_rate: 'Response rate',
  answers_rate1: 'Response',
  answers_rate2: 'rate',
  population_percentage: 'Population\nPercentage',
  // tslint:disable-next-line:max-line-length
  answers_rate_text: 'The amount of people that were included in the process and which completed the questionnaire are display in this table.',
  general_score: 'General score',
  results_by_dimension: 'Results by dimension',
  results_by_dimension_legend: '* Includes the Engagement Index questions',
  results_by_attribute: 'Results by attribute',
  results_by_attribute_legend: '* Does not include Engagement Index questions',
  results_by_dim_attr: 'Results by attributes',
  results_detail_by_var: 'Detailed results by variables',
  total: 'Total',
  attribute: 'Atributte',
  current_population: 'Current score',
  current_population_desc: 'Simple average of the total number of questions',
  current_population_items: 'Population score',
  weighted_score: 'Weighted score',
  weighted_score_desc: 'Weighted average of the 54 variables (50%) and the Engagement Index (50%)',
  enps_score_desc: 'Employee Net Promoter Score',
  engagement_index: 'Engagement Index',
  engagement_index_desc: 'Group the 6 engagement questions Engagement',
  preview_population: 'Previous score',
  questionnarie_population: 'Results by Questionnaire',
  committal_population: 'Results by Commital',
  rate: 'Rate',
  actual_organization: 'Score Organization',
  gap: 'Gap',
  culture_alignment: 'Culture alignment',
  purpose: 'Purpose',
  future_vision: 'Future vision',
  autonomy: 'Autonomy',
  strengths_and_talents: 'Strengths and talents',
  my_contribution: 'My contribution',
  motivation: 'Motivation',
  they_value_me: 'They value me',
  performance: 'Performance',
  recognized_organization: 'Recognized organization',
  organization_performance: 'Organization performance',
  pride_of_belonging_organization: 'Pride of belonging to the organization',
  recognized_area: 'Recognized area',
  area_performance: 'Area performance',
  pride_of_being_part_area: 'Pride of being part of the area',
  materials_and_equipment: 'Materials and equipment',
  offices_and_dress_code: 'Offices and dress code',
  balance_personal_professional: 'Balance between personal and professional life',
  being_myself: 'Being myself',
  freedom_of_expression: 'Freedom of expression',
  interpersonal_relationships: 'Interpersonal relationships',
  care_for_people: 'Care for people',
  inclusive_workplace: 'Inclusive workspace',
  respectful_treatment: 'Respectful treatment',
  equal_opportunities: 'Equal opportunities',
  salary_and_benefits: 'Salary and benefits',
  recognition_culture: 'Recognition culture',
  trust_quality_relationships: 'Trust and quality in relationships',
  responsibility_meeting_goals: 'Responsibility for meeting the goals',
  workload_balance: 'Worload balance',
  team_network: 'Team network',
  communication_team: 'Communication in my team',
  diversity: 'Diversity',
  agility_processes: 'Agility in the processes',
  innovation: 'Innovation',
  access_transparency_information: 'Access and transparency of information',
  autonomous_learning: 'Autonomous learning',
  development_potential: 'Development of my potential',
  expectations_role: 'Expectations of my role',
  career_plan: 'Career plan',
  future_organization: 'Future in the organization',
  horizontal_mobility: 'Horizontal mobility',
  learning_tracking: 'Learning tracking',
  genuine_interest: 'Genuine interest',
  admiration: 'Admiration',
  transparency_honesty: 'Transparency and honesty',
  clear_transparent_objectives: 'Clear and transparent objectives',
  coaching_feedback: 'Coaching and feedback',
  leader_access: 'Leader access',
  systematic_thinking: 'Systematic thinking',
  strategic_planning: 'Strategic planning',
  social_intelligence_collaboration: 'Social intelligence and collaboration',
  ranking_of_dimensions: 'Ranking of dimensions',
  dimension: 'Dimension',
  current_organization: 'Current organization',
  dimensions_with_higher_score: 'Dimensions with highest score',
  dimensions_with_lower_score: 'Dimensions with lowest score',
  dimensions_with_higher_dispersion: 'Dimensions with highest dispersion',
  dimensions_with_lower_dispersion: 'Dimensions with lowest dispersion',
  ranking_of_the_attributes: 'Ranking of attributes',
  attributes_with_higher_score: 'Attributes with highest score',
  attributes_with_lower_score: 'Attributes with lowest score',
  ranking_of_the_variables: 'Ranking of the variables',
  variable: 'Variable',
  variables_with_higher_score: 'Variables with highest score',
  variables_with_lower_score: 'Variables with lowest score',
  dispersion: 'Dispersion',
  dispersion_desc: 'Scores with higher scatter indicate less consensus and require more detailed analysis.',
  dispersion_by_attributes: 'Dispersion by attributes',
  dispersion_by_variables: 'Dispersion by variables',
  attributes_with_higher_dispersion: 'Attributes with highest dispersion',
  attributes_with_lower_dispersion: 'Attributes with lowest dispersion',
  preview_dispersion: 'Previous dispersion',
  current_dispersion: 'Current dispersion',
  current_dispersion_items: 'Population dispersion',
  variables_with_higher_dispersion: 'Variables with highest dispersion',
  variables_with_lower_dispersion: 'Variables with lowest dispersion',
  rates_by_dimension: 'Rates by dimension',
  variation: 'Variation',
  rates: 'Rates',
  rates_desc: 'The trend means the evolution of the result, how much the score increased or decreased compared to the previous Engagement assessment.',
  highest_positive_rate_attribute: 'Highest positive rates by attribute',
  highest_negative_rate_attribute: 'Highest negative rates by attribute',
  highest_positive_rate_variable: 'Highest positive rates by attribute',
  highest_negative_rate_variable: 'Highest negative rates by attribute',
  rates_by_variables: 'Rates by variables',
  rates_by_attributes: 'Rates by attributes',
  open_questions_summary: 'Close questions charts',
  word_questions_summary: 'Open words cloud',
  reports: 'Reports',
  general_title: 'General Report',
  general_desc: 'This report groups the responses of all the people who responded to the survey.',
  general_button: 'Generate engagement report',
  items_button: 'Generate engagement report - population',
  open_pie_title: 'Additional Questions',
  open_pie_desc: 'In these graphs you can find the scores of the additional questions.',
  demographic_population_button: 'Generate summary report by demographic cuts',
  demographic_title: 'By demographic items',
  demographic_desc: 'In this report you can choose and filter the scores of the different demographic cuts that you have configured when creating the assessment.',
  // tslint:disable-next-line:max-line-length
  demographic_population_title: 'Summary report by demographic cuts',
  // tslint:disable-next-line:max-line-length
  demographic_desc2: 'This report has the scores of the population filtered by the demographic cuts that you select below:',
  demographic_button: 'Generate engagement report by demographic item',
  display_demographic: 'Display demographic items',
  generated_reports: 'Generated Reports',
  generated_reports_desc: 'In this section you will find all the reports you have generated.<br>Depending on the number of participants, each report can take up to 30 minutes to generate. You can update the progress by reloading the page.<br>It is recommended to generate a maximum of 5 reports at the same time, to improve the performance of the platform.',
  already_generated_report: 'This report has already been generated. You can download it at the bottom, where all the generated reports are located.',
  population: 'Population',
  sent: 'Sent',
  obtained: 'Obtained',
  AdditionalDemographic1: 'Additional Demographics 1',
  AdditionalDemographic2: 'Additional Demographics 2',
  AcademicDegree: 'Academic degree',
  Age: 'Age',
  Antiquity: 'Antiquity',
  Charge: 'Charge',
  Country: 'Country',
  Headquarter: 'Headquarter',
  Department: 'Department',
  Gender: 'Gender',
  JobType: 'Job type',
  years_range: 'Between {min} and {max} years',
  dimention: 'Dimension',
  my_inspiration_chart: 'My\ninspiration',
  my_job_chart: 'My\njob',
  work_enviroment_chart: 'Positive work\nenvironment',
  my_team_chart: 'My       \nteam',
  my_team_chart2: 'My       \nteam',
  my_development_and_learning_chart: 'My\ndevelopment\nand learning',
  the_leaders_chart: 'The     \nleaders',
  the_leaders_chart2: 'The     \nleaders',
  cover: {
    byitems: 'Report by population',
    general: 'General report',
  },
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  active_employees: 'Active employees',
  participants: 'Participants',
  average: 'Sector average',
  people: 'people',
  note: 'Notes:',
  colors: 'Colors',
  dim_color: 'Dimension color',
  values: 'Values',
  meaning: 'Meaning',
  // tslint:disable-next-line:max-line-length
  heatmap_text: 'The following table shows the colors, value ranges, and meaning for the heat map that will be used in all the report tables.',
  previous_measurement: 'Previous masurement',
  strengths: 'Strengths',
  opportunities: 'Improvement opportunities',
  feelings: 'Feelings',
  what_is_it: 'What is it?',
  what_is_not: 'What is not?',
  critical_situation: 'Critical situation',
  requires_attention: 'Requires urgent attention',
  insufficient: 'Insufficient',
  aceptable: 'Acceptable',
  very_good: 'Very good',
  remarkable: 'Remarkable',
  how_to_read: 'How to read the report',
  title: 'OCC - Engagement Report',
  att_var: 'Attribute - Variable',
  // tslint:disable-next-line:max-line-length
  quote: ['"Engagement is defined as a positive, rewarding and work-related mental state characterized by vigor (high levels of energy and mental resilience), dedication (an exceptionally strong participation in work) and absorption (being totally absorbed at work)." Wilmar Schaufeli'],
  dispersion_by_dimensions: 'Dispersion by dimensions',
  open_questions_button: 'Additional Questions Charts',
  open_question_select_label: 'Select a question',
  org_dispersion: 'Organization dispersion',
  positive_attribute_footer: 'The following attributes has the same positive points than the attribute ',
  negative_attribute_footer: 'The following attributes has the same negative points than the attribute ',
  positive_variable_footer: 'The following variables has the same positive points than the variable ',
  negative_variable_footer: 'The following variables has the same negative points than the variable ',
  no_answers_modal_msg: 'The actual poll has not answers, so we cannot generate a report.',
  operation_init: 'A download process "Engagement Report" has started, this may take several minutes, you can see your progress by refreshing the page',
  operation_init_items: 'A download process "Engagement Report - Population" has started, this may take several minutes, you can see your progress by refreshing the page',
  input_generating_report: 'Generating report',
  input_update_progress: 'Update progress',
  input_failed_generation: 'Generation failed',
  input_download_report: 'Download report',
  input_new_report: 'Create new report',
  select_country_first: 'You must select at least one (1) country.',
  confirm_create_title: 'Confirm Report generation',
  report_cost: 'Report cost',
  balance_after: 'Balance after generating the report:',
};
