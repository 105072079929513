
export default {
  poll_title: 'Encuesta de Engagement',
  // tslint:disable-next-line:max-line-length
  poll_text: 'Lea cada una de las afirmaciones presentadas abajo y seleccione la opción que mejor representa su opinión según la siguiente escala: Totalmente en desacuerdo, Parcialmente en desacuerdo, Ni en desacuerdo ni de acuerdo, Parcialmente de acuerdo, Totalmente de acuerdo.',
  totally_disagree: 'Totalmente<br/> en desacuerdo',
  parcially_disagree: 'Parcialmente<br/> en desacuerdo',
  nor_agree_disagree: 'Ni en desacuerdo<br/> ni de acuerdo',
  parcially_agree: 'Parcialmente<br/> de acuerdo',
  totally_agree: 'Totalmente<br/> de acuerdo',
  unlikely: 'Nada probable',
  very_likely: 'Muy probable',
  entry_title: 'Bienvenido a su encuesta Engagement',
  // tslint:disable-next-line:max-line-length
  entry_text: 'Te invitamos a responder la encuesta de Engagement, seguros que con tus respuestas construiremos un mejor ambiente laboral. Cuentas con un enlace propio para tu encuesta, recuerda no compartirlo.',
  questions_65: '65 Preguntas',
  minutes_n: '{n} Minutos',
  unfinished: 'Inconcluso',
  complete_anonymity: 'Anonimato total',
  finish_title: 'Muchas gracias por haber completado la encuesta',
  finish_text: 'Su percepción es muy importante para nosotros.',
  new: 'Nueva encuesta Engagement',
  overview: 'Visión general',
  receptors: 'Encuestados',
  date: 'Fecha',
  questions: 'Preguntas',
  questionnaire: 'Cuestionario',
  revition: 'Revisión & Personalización',
  poll_name: 'Nombre de la encuesta',
  want_external_name: '¿Desea colocar un nombre externo para los encuestados?',
  external_name: 'Nombre externo para los encuestados',
  want_to_send: '¿A quién desea enviar esta encuesta?',
  hours: 'Hora de envío',
  hours_valid_until: 'Hora de cierre',
  minutes: 'Minuto',
  date_delivery: 'Fecha de lanzamiento',
  poll_valid_until: 'Encuesta válida hasta',
  time_zone: 'Zona horaria',
  want_send_reminders: '¿Desea enviar recordatorios?',
  reminders: 'Enviar recordatorio',
  want_open_question: '¿Desea añadir preguntas adicionales?',
  open_question: 'Preguntas adicionales',
  add_open_question: 'Añadir otra pregunta',
  remove_open_question: 'Eliminar última pregunta',
  min_open_question: 'Debe tener al menos una pregunta adicional disponible',
  max_open_question: 'Solamente puede {n} preguntas adicionales por encuesta',
  insert_question: 'Agregar enunciado de la pregunta {n}',
  insert_question_option: 'Agregar opción {o} para pregunta {n}',
  add_question_option: 'Añadir otra opción ',
  remove_question_option: 'Eliminar opción ',
  min_question_option: 'Debe tener al menos {n} opciones disponibles',
  max_question_option: 'Sólo puede indicar {n} opciones para una pregunta adicional',
  total_receptors: 'Total de encuestados: {n}',
  percent_total: 'Porcentaje del total a tomar',
  general_revition: 'Revisión general',
  settings: 'Ajustes',
  status: 'Estado',
  inter_name: 'Nombre interno',
  send_reminders: 'Envio de recordatorios',
  personalization: 'Personalización de correos electrónicos',
  poll_invitation: 'invitación a la encuesta',
  reminder_mail: 'Correo de recordatorio',
  tk_message: 'Mensaje de agradecimiento',
  message_subject: 'Asunto del mensaje',
  body_message: 'Cuerpo del mensaje',
  message_from_the_company: 'Mensaje por la empresa',
  created_engagement: '¡Encuesta creada exitosamente!',
  id: 'Id',
  name: 'Nombre',
  participants: 'Participantes',
  period: 'Período',
  action: 'Acciones',
  filter_by: 'Filtrar por...',
  look_for: 'Buscar una encuesta',
  no_data: 'No hay encuestas para mostrar',
  engagement_realized: 'Engagement realizados',
  add_engagement: 'Crear Engagement',
  pending: 'Pendiente',
  completed: 'Completada',
  cancelled: 'Cancelada',
  creating: 'Creando',
  editing: 'Editando',
  launching: 'Lanzamiento',
  progress: 'En progreso',
  building: 'En construcción',
  at: 'A las',
  at_the: 'al',
  download: 'Descargar',
  aprogress: 'Progreso',
  edit: '¡Encuesta editada exitosamente!',
  tooltip_name: 'Nombre interno de la encuesta',
  tooltip_display_name: 'Nombre de la encuesta para los encuestados',
  summary: 'Resumen de la encuesta',
  to_edit: 'Editar encuesta',
  total_progress: 'Progreso total',
  end_date: 'Fecha cierre',
  poll_completed: 'Encuestas completadas',
  poll_no_completed: 'Encuestas no completadas',
  resend: 'Reenviar encuestas no completadas',
  resend_success: '¡Encuestas reenviadas exitosamente!',
  question_n: '{n} Preguntas',
  no_actions: 'No disponibles',
  questionnaire_e: 'Cuestionario Engagement',
  // tslint:disable-next-line:max-line-length
  pdf_explained: 'En este archivo se puden observar las preguntas pertenecientes al cuestionario "{name}" agrupadas por dimensión y atributo.',
  open_question_info: 'Puede indicar preguntas personalizadas adicionales, indicando las posibles respuestas.',
  // tslint:disable-next-line:max-line-length
  congratulations: '¡Felicitaciones, completaste el <span class="headline" style="color: #44C156">{progress}%</span> de la encuesta!',
  incompleted: 'Incompleta',
  no_questionnaries_title: 'No puede continuar en la creación de esta encuesta',
  // tslint:disable-next-line:max-line-length
  no_questionnaries_body: 'No existen cuestionarios activos para su empresa, por esta razón no puede continuar con la creación de encuestas de este tipo Por favor pongase en contacto con el administrador del sistema para solventar este problema.',
  no_balance: 'Sin balance suficiente',
  no_balance_msg: 'Empresa sin saldo suficiente, si continua el proceso, el sistema intentará consumir saldos autorizados.',
  confirm_create_title: 'Confirme la creación de la medición',
  workshop_cost: 'Costo de la Medición',
  poll_paid: 'Medición ya ha sido pagada.',
  your_balance: 'Su saldo:',
  token_unit: 'OCC Tokens',
  balance_after: 'Saldo luego de crear la medición:',
  non_active_employees: 'Debe tener al menos un colaborador activo para esta encuesta.',
  operation_failed: 'Operación no exitosa, se recomienda:',
  acquire_tokens: 'Adquirir más OCC Tokens',
  go_to_report: 'Ir al reporte',
  poll_invitation_content: {
    subject: 'Bienvenido a la medición de Engagement',
    body: `Bienvenido(a) al proceso de valoración de Engagement, la cual nos permitirá obtener información valiosa acerca de los niveles de compromiso y pertenencia en nuestra compañía.
    <br/>Información clave sobre esta encuesta:
    <ul><li>La valoración tarda menos de 30 minutos en completarse.</li></ul>
    <ul><li>La información entregada será manejada de manera confidencial y será utilizada con fines estadísticos.</li></ul>
    <ul><li>Por favor contestar con total sinceridad.</li></ul>
    <ul><li>Para una mejor experiencia use Google Chrome, recuerde que debe estar conectado a internet.</li></ul>
    <ul><li>Si tiene alguna duda o evidencia algún problema con la encuesta, por favor no dude en contactarnos en el correo: <a href="mailto:info@occsolutions.org">info@occsolutions.org</a>.</li></ul>
    <ul><li>Recuerde que este link es personal y no se debe compartir.</li></ul>
    <br/>De antemano le agradecemos por su tiempo y sus valiosos aportes en este importante proceso.
    `
  },
  reminder_mail_content: {
    subject: 'Recordatorio medición Engagement',
    body: `Te recordamos culminar el proceso de valoración de Engagement, la cual nos permitirá obtener información valiosa acerca de los niveles de compromiso y pertenencia de nuestra compañía.
    <br/>Información clave sobre esta encuesta:
    <ul><li>La valoración tarda menos de 30 minutos en completarse.</li></ul>
    <ul><li>La información entregada será manejada de manera confidencial y será utilizada con fines estadísticos.</li></ul>
    <ul><li>Por favor contestar con total sinceridad.</li></ul>
    <ul><li>Para una mejor experiencia use Google Chrome, recuerde que debe estar conectado a internet.</li></ul>
    <ul><li>Si tiene alguna duda o evidencia algún problema con la encuesta, por favor no dude en contactarnos en el correo: <a href="mailto:info@occsolutions.org">info@occsolutions.org</a>.</li></ul>
    <ul><li>Recuerde que este link es personal y no se debe compartir.</li></ul>
    <br/>De antemano le agradecemos por su tiempo y sus valiosos aportes en este importante proceso.
    `
  }
};
