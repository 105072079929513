<template>
  <v-container>
    <v-row justify="start">
      <v-col cols="12" sm="6">
        <h4 class="display-1 mb-3">{{ $t('engagement.to_edit') }}</h4>
      </v-col>

      <v-col cols="12">
        <v-card class="mt-4">
          <x-stepper
            :step="step"
            :headers="stepperHeaders"
            :max-sm="1"
            :max-md="2"
            :max-lg="3"
            :max-xl="4"
            @step="step = $event"
          >
            <ValidationObserver ref="step1">
              <x-poll-steps-overview
                :engagement="engagement"
                step="1"
                nextAction="input.next"
                prevAction="input.cancel"
                @changeStep="verifyStepChanged"
              ></x-poll-steps-overview>
            </ValidationObserver>

            <ValidationObserver ref="step2">
              <x-poll-steps-date
                :engagement="engagement"
                step="2"
                nextAction="input.next"
                prevAction="input.cancel"
                @changeStep="verifyStepChanged"
              ></x-poll-steps-date>
            </ValidationObserver>

            <ValidationObserver ref="step3">
            <x-step-question
              :engagement="engagement"
              step="3"
              nextAction="input.next"
              prevAction="input.cancel"
              @changeStep="verifyStepChanged"
            ></x-step-question>
            </ValidationObserver>

            <ValidationObserver ref="step4">
            <x-step-revition
              :engagement="engagement"
              step="4"
              nextAction="input.next"
              prevAction="input.cancel"
              @changeStep="verifyStepChanged"
              :edit="edit"
            ></x-step-revition>
            </ValidationObserver>
          </x-stepper>
        </v-card>
      </v-col>
    </v-row>
    <x-loading v-if="loading" :display="loading"/>
  </v-container>
</template>

<script>
import EngagementsService from '../../services/engagements';

import XStepQuestion from './steps/question.vue';
import XStepRevition from './steps/revition.vue';
import {mapState} from "vuex";

export default {
  components: {
    XStepQuestion,
    XStepRevition,
  },
  data() {
    return {
      stepperHeaders: [
        'engagement.poll_name',
        'engagement.date',
        'engagement.questions',
        'engagement.revition',
      ],
      engagement: {
        name: '',
        displayName: '',
        selection_type: '',
        selection_details: '',
        timeZone: '',
        deliveredAt: {
          value: '',
          hour: '23:00',
        },
        model: null,
        validUntil: {
          value: '',
          hour: '23:00',
        },
        status: '',
        emailData: '',
        switchName: false,
        switchQuestion: false,
        radios: 1,
        questionnaires: [],
        switchDate: false,
        reminders: [{
          value: '',
          hour: '23:00',
        }],
        percent_total: 0,
        opens: [],
        pollInvitation: {
          subject: '',
          body: '',
          companyMessage: '',
        },
        reminderMail: {
          subject: '',
          body: '',
          companyMessage: '',
        },
        thankMessage: '',
        active: null,
        offset: '',
        totalReceivers: 0
      },
      loading: false,
      step: 1,
      edit: true,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user,
    }),
  },
  methods: {
    initForm () {
      if (this.user.enterprise) {
        this.engagement.enterprise = this.user.enterprise
        this.engagement.enterpriseId = this.user.enterprise.id
      }
    },
    getEngagement() {
      EngagementsService.getOneById(this.$route.params.id)
        .then((res) => {
          this.reponseParse(res.item);
          const status = res.item.status;
          const invalidStatuses = ['completed', 'cancelled', 'creating', 'editing', 'launching'];
          if (invalidStatuses.includes(status)) { this.$router.go(-1); }
        })
    },
    reponseParse(res) {
      if (res.emailData !== "{ pollInvitation: '', reminderMail: '', thankMessage: ''}") {
        const emailData = JSON.parse(res.emailData);
        this.engagement.pollInvitation = emailData.pollInvitation;
        this.engagement.reminderMail = emailData.reminderMail;
        this.engagement.thankMessage = emailData.thankMessage;
      }

      this.engagement.name = res.name;
      this.engagement.displayName = res.displayName;
      this.engagement.switchName = !!this.engagement.displayName;
      this.engagement.timeZone = res.timeZone;
      this.engagement.status = res.status;

      this.engagement.deliveredAt = this.getDateEngagement(res.deliveredAt);
      this.engagement.validUntil = this.getDateEngagement(res.validUntil);
      this.engagement.totalReceivers = res.totalReceivers

      this.engagement.radios = res.questionnaireId;

      this.engagement.switchQuestion = Boolean(res.openQuestion.length);
      if (this.engagement.switchQuestion) {
        res.openQuestion.forEach(open => {
          this.engagement.opens.push(JSON.parse(open.question));
        });
      } else {
        this.engagement.opens.push({
          question: '',
          options: ['', ''],
        });
      }

      res.reminders.forEach((element, i) => {
        this.engagement.switchDate = true;
        this.engagement.reminders[i] = this.getDateEngagement(element.date);
      });
    },
    getDateEngagement(ref) {
      if (!ref) {
        return {
          value: '',
          hour: '23:00',
        }
      }
      const date = ref.split('T')
      const time = date[1].split(':')
      return {
        value: date[0],
        hour: time[0] + ':00',
      }
    },
    onSubmit(step) {
      if (step && step < this.step) {
        this.step = step
        return
      }
      const onValidate = success => {
        if (!success) {
          const error = this.$t('errors.validator/invalid-input')
          this.$store.dispatch('alert/error', error)
          return
        }
        if (step === 5) {
          return this.update()
        } else {
          this.step = step
        }
      };
      switch (this.step) {
        case 1: this.$refs.step1.validate().then(onValidate); break;
        case 2: this.$refs.step2.validate().then(onValidate); break;
        case 3: this.$refs.step3.validate().then(onValidate); break;
        case 4: this.$refs.step4.validate().then(onValidate); break;
      }
    },
    verifyStepChanged(step) {
      if (step === 0) {
        return this.$router.push('/engagement')
      }
      this.onSubmit(step)
    },
    update() {
      this.loading = true;
      let pollInvitationFile = null;
      let reminderMailFile = null;

      if (this.engagement.pollInvitation.file && typeof this.engagement.pollInvitation.file === 'object') {
        pollInvitationFile = this.engagement.pollInvitation.file;
        this.engagement.pollInvitation.file = this.engagement.pollInvitation.file.name
      }
      if (this.engagement.reminderMail.file && typeof this.engagement.reminderMail.file === 'object') {
        reminderMailFile = this.engagement.reminderMail.file;
        this.engagement.reminderMail.file = this.engagement.reminderMail.file.name;
      }

      return EngagementsService.update(this.$route.params.id, this.engagement)
        .then(() => {
          return pollInvitationFile ? EngagementsService.sendInvitationFiles(this.$route.params.id, { pollInvitationFile }) : Promise.resolve();
        })
        .then(() => {
          return reminderMailFile ? EngagementsService.sendReminderFiles(this.$route.params.id, { reminderMailFile }) : Promise.resolve();
        })
        .then(() => {
          this.$store.dispatch('alert/success', this.$t('engagement.edit'))
          this.loading = false;
          this.$router.push('/engagement')
        })
        .catch((err) => {
          this.loading = false;
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        });
    },
  },
  created() {
    this.getEngagement()
    this.initForm()
  },
};
</script>

<style>
  #enterprise-create div.v-stepper,
  #enterprise-create div.v-stepper div.v-stepper__items,
  #enterprise-create div.v-stepper div.v-stepper__items div.v-stepper__wrapper {
    overflow: visible !important;
  }
</style>
