<template>
  <v-dialog persistent
    v-model="showDialog"
    width="340px"
  >
    <v-card tile>
      <v-card-title>
        Sábana de Datos
      </v-card-title>

      <v-card-text class="text-center pt-1 pb-0">
        <v-progress-circular indeterminate
          v-if="loading"
          :size="77"
          :width="4"
          color="primary"
          class="my-4"
        >
          <span class="caption">
            {{batchCount}}/{{totalAnswers}}
          </span>
        </v-progress-circular>
        <div v-else class="pt-4 pb-7">
          <!---------- Questionnaire ---------->
          <v-btn block outlined
            color="success darken-2"
            :loading="loadingBtn1"
            @click="downloadXls1()"
          >
            Cuestionario <v-icon size="21" class="ml-2">mdi-file-excel</v-icon>
          </v-btn>
          <!-------------- ENPS -------------->
          <v-btn block outlined
            color="success darken-2"
            class="mt-5"
            :loading="loadingBtn2"
            @click="downloadXls2()"
          >
            ENPS <v-icon size="21" class="ml-2">mdi-file-excel</v-icon>
          </v-btn>
          <!--------- Open Questions --------->
          <v-btn block outlined
            v-if="pollType !== 'Pulse'"
            color="success darken-2"
            class="mt-5"
            :loading="loadingBtn3"
            @click="downloadXls3()"
          >
            Preguntas abiertas <v-icon size="21" class="ml-2">mdi-file-excel</v-icon>
          </v-btn>
          <!------ Additional Questions ------>
          <v-btn block outlined
            v-if="pollType !== 'Pulse'"
            color="success darken-2"
            class="mt-5"
            :loading="loadingBtn4"
            @click="downloadXls4()"
          >
            Preguntas adicionales <v-icon size="21" class="ml-2">mdi-file-excel</v-icon>
          </v-btn>
        </div>
      </v-card-text>

      <v-card-actions class="justify-end px-2">
        <v-btn
          :disabled="loading"
          @click="$emit('close')"
        >
          {{ $t('input.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import resolver from '@/utils/resolver'

import attributesService from '@/services/attributes'
import demographicItemsService from '@/services/demographic-items'
import dimensionsService from '@/services/dimensions'
import employeesService from '@/services/employees'
import engagementsService from '@/services/engagements'
import pulsesService from '@/services/pulses'
import wordsQuestionsService from '@/services/word-questions'
import openQuestionsService from '@/services/open-questions'

import xlsxDownload from '@/utils/xlsx-download'

import { ageRanges, antiquityRanges } from '@/utils/ranges'

export default {
  props: {
    pollType: {
      type: String,
      default: 'Engagement'
    },
    evaluationId: Number,
    questionnaire: Array,
    // openQuestion: Array,// Preguntas Adicionales
    showDialog: Boolean
  },
  data () {
    return {
      loading: true,
      loadingBtn1: false,
      loadingBtn2: false,
      loadingBtn3: false,
      loadingBtn4: false,
      formattedDemographics: {},
      totalAnswers: 0,
      dimensions: [],
      attributes: [],
      wordsQuestions: [],
      additionalQuestions: [],
      batchCount: 0,
      jsonResponse: [],
      evaluationData: [],
      enpsData: [],
      wordsQuestionsData: [],
      additionalQuestionsData: [],
      participants: [],
      dKeys: {
        academicDegree: 'academicDegreeId',
        age: 'birthdate',
        antiquity: 'admission',
        charge: 'chargeId',
        country: 'countryId',
        departments: 'departmentId',
        gender: 'genderId',
        headquarter: 'headquarterId',
        jobTypes: 'jobTypeId',
        optionalDemo1: 'additionalDemographic1Id',
        optionalDemo2: 'additionalDemographic2Id'
      }
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  watch: {
    showDialog (val) {
      if (val) {
        if (!this.jsonResponse.length) {
          this.getInitialData()
        } else {
          this.generateXlsx()
        }
      }
    }
  },
  methods: {
    formatDemoItems (demographicItems) {
      const data = {}
      Object.keys(demographicItems).forEach(key => {
        if (demographicItems[key].length) {
          data[this.dKeys[key]] = {}
          demographicItems[key].forEach(d => {
            data[this.dKeys[key]][d.id] = d.name || d.translate.label
          })
        }
      })

      return data
    },
    getInitialData () {
      const evaluationsService = this.pollType === 'Pulse' ? pulsesService : engagementsService
      resolver.all({
        demographics: demographicItemsService.getDemographicsItemsByEntreprise(this.user.lang),
        dimensions: dimensionsService.listAll(this.user.lang),
        attributes: attributesService.listAll(this.user.lang),
        wordsQuestions: wordsQuestionsService.list(),
        openQuestions: openQuestionsService.list(this.pollType, this.evaluationId),
        employees: employeesService.getEmployees(),
        total: evaluationsService.getAnswersCount(this.evaluationId)
      })
        .then(async (res) => {
          this.formattedDemographics = await this.formatDemoItems(res.demographics.data)
          this.dimensions = res.dimensions.items
          this.attributes = res.attributes.items
          this.wordsQuestions = res.wordsQuestions.items
          this.additionalQuestions = res.openQuestions.items
          this.participants = res.employees.items
          this.totalAnswers = res.total.count
          this.getAnswers()
        })
        .catch(err => {
          console.log(err)
        })
    },
    getAnswers () {
      const evaluationsService = this.pollType === 'Pulse' ? pulsesService : engagementsService
      evaluationsService.getAnswersWithParticipant(this.evaluationId, this.batchCount)
        .then(async (res) => {
          this.batchCount += res.length
          const formattedAnswers = await this.formatAnswers(res)
          this.jsonResponse = [...this.jsonResponse, ...formattedAnswers]

          if (this.batchCount !== this.totalAnswers) {
            setTimeout(async () => {
              await this.getAnswers()
            }, 10000)
          } else {
            this.loading = false
            this.generateXlsx()
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    formatAnswers (answers) {
      answers.forEach(a => {
        if (a.participant) {
          const found = this.participants.find(x => x.employeeId === a.participant.employeeId)
          if (found) {
            a.participant['identifyDocument'] = found.identifyDocument
            a.participant['email'] = found.employee.email
          }
        }

        Object.keys(a.demographic).forEach(key => {
          if (this.formattedDemographics[key]) {
            const cleanKey = key.endsWith('Id') ? key.slice(0, -2) : key
            a.demographic[cleanKey] = this.formattedDemographics[key][a.demographic[key]]
            delete a.demographic[key]
          }
        })
      })

      return answers
    },
    getRange (date, type) {
      const today = new Date()
      const formattedDate = new Date(date)

      const diff = (today.getTime() - formattedDate.getTime()) / 1000
      const diffInDays = diff / (60 * 60 * 24)

      const year = diffInDays / 365.25

      const rng = type === 'age' ? ageRanges : antiquityRanges
      const found = rng.find(x => year >= x.min && year < x.max)

      return found ? found.text[this.user.lang] : ''
    },
    generateXlsx () {
      this.jsonResponse.forEach(async (item) => {
        const obj = {
          País: item.demographic.country,
          Sede: item.demographic.headquarter,
          Departamento: item.demographic.department,
          Cargo: item.demographic.charge,
          'Tipo de Contratación': item.demographic.jobType,
          'Nivel Académico': item.demographic.academicDegree,
          Genero: item.demographic.gender,
          'ID Interno': item.participant ? item.participant.id : '',
          'Documento de Identidad': item.participant ? item.participant.identifyDocument : '',
          Correo: item.participant ? item.participant.email : '',
          Edad: this.getRange(item.demographic.birthdate, 'age'),
          Antigüedad: this.getRange(item.demographic.admission, 'antiquity'),
          AddDemo1: item.demographic.additionalDemographic1,
          AddDemo2: item.demographic.additionalDemographic2
        }

        if (['committal', 'questionnaire'].includes(item.questionType)) {
          // Load Evaluation XLS data
          const foundQ = this.questionnaire.find(x => x.questionId === item.questionId && x.type === item.questionType)
          const foundDim = this.dimensions.find(x => x.id === foundQ.dimensionId)

          const clone = JSON.parse(JSON.stringify(obj))
          clone.Dimensión = foundDim.translate.label
          clone.Atributo = ''
          clone.Variable = ''
          if (item.questionType !== 'committal') {
            const foundAttr = this.attributes.find(x => x.id === foundQ.attributeId)
            const foundVar = foundAttr.variables.find(x => x.id === foundQ.variableId)
            clone.Atributo = foundAttr.translate.label
            clone.Variable = foundVar.translate.label
          }
          clone.Pregunta = foundQ.label[this.user.lang]
          clone.Respuesta = item.answer
          if (item.questionType === 'committal' && foundDim.id === 3) {
            const cloneENPS = JSON.parse(JSON.stringify(clone))
            delete cloneENPS.Atributo
            delete cloneENPS.Variable
            this.enpsData.push(cloneENPS)
          } else {
            clone.Puntaje = `${Number(item.answer) * 25}%`
            this.evaluationData.push(clone)
          }
        }

        // Load Open Questions XLS data
        if (item.questionType === 'words') {
          const found = this.wordsQuestions.find(x => x.id === item.questionId)
          const t = found.translations.find(x => x.lang === this.user.lang)

          JSON.parse(item.answer).forEach(answer => {
            const clone2 = JSON.parse(JSON.stringify(obj))
            clone2.Pregunta = t.label
            clone2.Respuesta = answer
            this.wordsQuestionsData.push(clone2)
          })
        }

        // Load Additional Questions XLS data
        if (item.questionType === 'open') {
          const found = this.additionalQuestions.find(x => x.id === item.questionId)
          const formattedQ = JSON.parse(found.question)

          const clone3 = JSON.parse(JSON.stringify(obj))
          clone3.Pregunta = formattedQ.question
          clone3.Respuesta = item.answer
          this.additionalQuestionsData.push(clone3)
        }
      })
    },
    downloadXls1 () {
      this.loadingBtn1 = true
      setTimeout(async () => {
        await xlsxDownload(
          this.evaluationData,
          'Cuestionario',
          'Sabana de Datos - Cuestionario.xlsx',
          true
        )
        this.loadingBtn1 = false
      }, 1000)
    },
    downloadXls2 () {
      this.loadingBtn2 = true
      setTimeout(async () => {
        await xlsxDownload(
          this.enpsData,
          'ENPS',
          'Sabana de Datos - ENPS.xlsx',
          true
        )
        this.loadingBtn2 = false
      }, 1000)
    },
    downloadXls3 () {
      this.loadingBtn3 = true
      setTimeout(async () => {
        await xlsxDownload(
          this.wordsQuestionsData,
          'Abiertas',
          'Sabana de Datos - Preguntas Abiertas.xlsx',
          true
        )
        this.loadingBtn3 = false
      }, 1000)
    },
    downloadXls4 () {
      this.loadingBtn4 = true
      setTimeout(async () => {
        await xlsxDownload(
          this.additionalQuestionsData,
          'Adicionales',
          'Sabana de Datos - Preguntas Adicionales.xlsx',
          true
        )
        this.loadingBtn4 = false
      }, 1000)
    }
  }
}
</script>
