
<template>
  <v-stepper-content :step="step">
    <v-card>
      <v-col cols="12" class="pa-3 headline">
        {{ $t('engagement.poll_name') }}
        <v-divider></v-divider>
        <v-spacer></v-spacer>
      </v-col>
      <v-container>
        <v-row row wrap>
          <v-col cols="12">
            <v-tooltip bottom color='blue'>
              <template v-slot:activator="{ on }">
                <x-inputs-input
                  :input="engagement.name"
                  @updateInput="($event) => engagement.name = $event"
                  v-on="on"
                  light
                  :counter="counter"
                  :label="$t('engagement.poll_name')"
                  autofocus
                  rules="required"
                  name="poll_name"
                  :append-outer-icon="$t('help.icon')"
                  :help-message="$t('help.engagement.create.name')"
                ></x-inputs-input>
              </template>
              <span>{{ $t('engagement.tooltip_name') }}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="12">
            <v-switch
              v-model="engagement.switchName"
              :label="$t('engagement.want_external_name')"
            ></v-switch>
            <v-tooltip
              bottom
              color='blue'
            >
              <template v-slot:activator="{ on }">
                <x-inputs-input
                  v-on="on"
                  v-show="engagement.switchName"
                  :rules="engagement.switchName ? 'required' : ''"
                  :input="engagement.displayName"
                  @updateInput="($event) => engagement.displayName = $event"
                  light
                  :counter="counter"
                  :label="$t('engagement.external_name')"
                  name="external_name"
                  :append-outer-icon="$t('help.icon')"
                  :help-message="$t('help.engagement.create.displayName')"
                ></x-inputs-input>
              </template>
              <span>{{ $t('engagement.tooltip_display_name') }}</span>
            </v-tooltip>
          </v-col>
          <!-- Load/Update Participants Reminder -->
          <v-col cols="12" sm="8" class="pt-5 caption primary--text">
            {{ $t('help.engagement.create.remember_load_update_collaborators') }}
          </v-col>
          <!-- Engagement Participant Count -->
          <v-col cols="12" sm="4" v-if="engagement.totalReceivers" class="text-right headline">
            {{ $t('engagement.total_receptors', {n: `${engagement.totalReceivers}`}) }}
          </v-col>
          <v-col cols="12" sm="6">
            <v-btn
              block
              large
              @click="changeStep(+step - 1)"
            >{{ $t(prevAction) }}</v-btn>
          </v-col>

          <v-col cols="12" sm="6">
            <x-async-btn
              class="white--text"
              :action="validateForm"
              large
              block
            >{{ $t(nextAction) }}
            </x-async-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-stepper-content>
</template>

<script>

export default {
  props: {
    engagement: Object,
    step: String,
    nextAction: String,
    prevAction: String,
  },
  data() {
    return {
      counter: 75,
    };
  },
  methods: {
    changeStep(step) {
      this.$emit('changeStep', step);
    },
    validateForm() {
      return this.validateCounter()
        .then(valid => valid ? this.changeStep(+this.step + 1) : undefined);
    },
    validateCounter() {
      return new Promise((resolve, reject) => {
        if (this.engagement.name.length > this.counter || this.engagement.displayName.length > this.counter) {
          this.$store.dispatch('alert/error', this.$t('errors.engagements/max-counter'))
          return reject(new Error(this.$t('errors.engagements/max-counter')))
        }
        return resolve(true);
      })
    }
  }
}
</script>
