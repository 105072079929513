
export default {
  poll_title: 'Engagement Poll',
  // tslint:disable-next-line:max-line-length
  poll_text: 'Read each one of the assertions presented down bellow and select the one that fits the best with your opinion based on the next scale: Totally disagree, Parcially disagree, Nor disagree nor agree, Parcially agree, Totally agree.',
  totally_disagree: 'Totally<br/> disagree',
  parcially_disagree: 'Parcially<br/> disagree',
  nor_agree_disagree: 'Nor disagree<br/> nor agree',
  parcially_agree: 'Parcially<br/> agree',
  totally_agree: 'Totally<br/> agree',
  unlikely: 'Unlikely',
  very_likely: 'Very likely',
  entry_title: 'Welcome to your Engagement poll',
  // tslint:disable-next-line:max-line-length
  entry_text: 'We invite you to answer the Engagement survey, we are sure that with your answers we will build a better work environment. You have your own link for your survey, remember not to share it.',
  questions_65: '65 Questions',
  minutes_n: '{n} Minutes',
  unfinished: 'Unfinished',
  complete_anonymity: 'Complete anonymity',
  finish_title: 'Thanks a lot for completing the poll',
  finish_text: 'Your perception is very important for us.',
  new: 'New Engagement poll',
  overview: 'Overview',
  receptors: 'Respondents',
  date: 'Date',
  questions: 'Questions',
  questionnaire: 'Questionnaire',
  revition: 'Review & Personalization',
  poll_name: 'Name of poll',
  want_external_name: 'Do you want to place an external name for the respondents?',
  external_name: 'External name for the respondents',
  want_to_send: 'Who do you want to send this poll to?',
  hours: 'Hour',
  hours_valid_until: 'Close hour',
  minutes: 'Minute',
  date_delivery: 'Date of delivery',
  poll_valid_until: 'Poll valid until',
  time_zone: 'Time zone',
  want_send_reminders: '¿Do you want to send reminders?',
  reminders: 'Send reminder',
  want_open_question: 'Do you want to include additional questions?',
  open_question: 'Additional question',
  add_open_question: 'Add another question',
  remove_open_question: 'Remove last question',
  min_open_question: 'You must have at least one additional question available',
  max_open_question: 'You can only {n} additional questions per survey',
  insert_question: 'Add statement of question {n}',
  insert_question_option: 'Add option {o} for question {n}',
  add_question_option: 'Add another option',
  remove_question_option: 'Remove option ',
  min_question_option: 'You must have at least {n} options available',
  max_question_option: 'You can only indicate {n} options for an additional question',
  total_receptors: 'Total receivers: {n}',
  percent_total: 'Percentage of the total to be taken',
  general_revition: 'General Review',
  settings: 'Settings',
  status: 'Status',
  inter_name: 'Inter name',
  send_reminders: 'Send reminders',
  personalization: 'Personalization of emails',
  poll_invitation: 'Poll invitation',
  reminder_mail: 'Reminder email',
  tk_message: 'Thank you message',
  message_subject: 'Message subject',
  body_message: 'Message body',
  message_from_the_company: 'Message from the company',
  created_engagement: 'Poll created successfully!',
  id: 'Id',
  name: 'Name',
  participants: 'Participants',
  period: 'Period',
  action: 'Actions',
  filter_by: 'Filter by...',
  look_for: 'Look for an poll',
  no_data: 'There are none polls to display',
  engagement_realized: 'Realized Engagement',
  add_engagement: 'Add Engagement',
  pending: 'Pending',
  completed: 'Completed',
  cancelled: 'Cancelled',
  progress: 'In Progress',
  creating: 'Creating',
  editing: 'Editing',
  launching: 'Launching',
  building: 'Building',
  at: 'at',
  at_the: 'at',
  download: 'Download',
  aprogress: 'Progress',
  edit: 'Poll edited successfully!',
  tooltip_name: 'Internal name of the poll',
  tooltip_display_name: 'Name of the poll for receivers',
  summary: 'Poll Summary',
  to_edit: 'Edit poll',
  total_progress: 'Total progress',
  end_date: 'Closing date',
  poll_completed: 'Completed polls',
  poll_no_completed: 'Polls not completed',
  resend: 'Resend polls not completed',
  resend_success: 'Successfully forwarded polls!',
  question_n: '{n} Questions',
  no_actions: 'No available',
  questionnaire_e: 'Engagement Questionnaire',
  // tslint:disable-next-line:max-line-length
  pdf_explained: 'In this file you can see the questions belonging to the questionnaire "{name}" grouped by dimension and attribute',
  open_question_info: 'You can indicate additional custom questions, indicating possible answers.',
  // tslint:disable-next-line:max-line-length
  congratulations: 'Contratulations, you have completed the <span class="headline" style="color: #44C156">{progress}%</span> of the poll!',
  incompleted: 'Incompleted',
  no_questionnaries_title: 'You cannot continue creating this poll',
  // tslint:disable-next-line:max-line-length
  no_questionnaries_body: 'There is not questionnaires active for your enterprise, so you cannnot continue creating polls of this type. Please contact the system administrator to solve this problem.',
  no_balance: 'Not enough balance',
  no_balance_msg: 'Company with not enough balance, if you continue the process, the system will try to consume authorized balances.',
  confirm_create_title: 'Confirm measurement creation',
  workshop_cost: 'Measurement cost',
  poll_paid: 'Measurement has been already paid.',
  your_balance: 'You balance:',
  token_unit: 'OCC Tokens',
  balance_after: 'Balance after creating the measurement:',
  non_active_employees: 'There must be at least one active contributor for this survey.',
  operation_failed: 'Unsuccessful operation, recommended:',
  acquire_tokens: 'Get more OCC Tokens',
  go_to_report: 'Go to report',
  poll_invitation_content: {
    subject: 'Welcome to engagement poll',
    body: `Welcome to te engagement valuation process, that which will allow us get valuable information about different commitment levels and belonging of our company.
    <br/>Keys about this poll:
    <ul><li>Assessment takes less over 30 minutes to complete.</li></ul>
    <ul><li>The information provided will be kept confidential and used for statistical purposes. </li></ul>
    <ul><li>Please answer with total sincerity.</li></ul>
    <ul><li>For a better experience use Google Chrome, remember that you must be connected to the internet.</li></ul>
    <ul><li>If you have any questions or evidence of a problem with the survey, please do not hesitate to contact us in this email: <a href="mailto:info@occsolutions.org">info@occsolutions.org</a>.</li></ul>
    <ul><li>Remember that this link is personal and should not be shared.</li></ul>
    <br/>We thank you in advance for your time and valuable contributions in this important process.
    `
  },
  reminder_mail_content: {
    subject: 'Engagement poll reminder',
    body: `We remind you to complete the Engagement assessment process, that which will allow us get valuable information about different commitment levels and belonging of our company.
    <br/>Keys about this poll:
    <ul><li>Assessment takes less over 30 minutes to complete.</li></ul>
    <ul><li>The information provided will be kept confidential and used for statistical purposes. </li></ul>
    <ul><li>Please answer with total sincerity.</li></ul>
    <ul><li>For a better experience use Google Chrome, remember that you must be connected to the internet.</li></ul>
    <ul><li>If you have any questions or evidence of a problem with the survey, please do not hesitate to contact us in this email: <a href="mailto:info@occsolutions.org">info@occsolutions.org</a>.</li></ul>
    <ul><li>Remember that this link is personal and should not be shared.</li></ul>
    <br/>We thank you in advance for your time and valuable contributions in this important process.
    `
  }
};
